<template>
  <b-card
    class="card-item h-100"
    no-body
    footer-bg-variant="white"
    footer-class="border-top-0 px-3"
  >
    <b-card-body class="p-0 bg-black">
      <video
        class="p-0"
        width="100%"
        height="100%"
        preload="metadata"
        :src="`${asset.src}#t=1.5`"
      ></video>
    </b-card-body>
    <template v-slot:footer>
      <b-row class="no-gutters mt-3">
        <b-col>
          <div class="mb-3 break-world">
            <p class="title__3">
              {{ asset.name }}
            </p>
            <!-- <p class="subtitle__1 mb-0">
              {{ $t(type) }}
            </p> -->
            <!-- <p class="subtitle__1">{{ $t(status) }}</p> -->
          </div>
        </b-col>
        <!-- <b-col class="col-auto">
          <img
            src="./../../assets/general-icons/pencil.svg"
            class="action__icon clickable"
            @click="editFile"
          />
        </b-col> -->
      </b-row>
      <hr class="my-2 mx-n3" />
      <b-row class="no-gutters justify-content-end">
        <b-col class="col-auto">
          <img
            src="./../../assets/general-icons/trash.svg"
            class="action__icon clickable"
            @click="removeAsset"
          />
        </b-col>
      </b-row>
    </template>
  </b-card>
</template>

<script>
export default {
  props: {
    asset: {
      type: Object,
      required: true
    }
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    removeAsset() {
      this.$store.dispatch("projects/deleteProjectAsset", {
        assetId: this.asset._id,
        projectId: this.$route.params.projectId
      });
    }
  }
};
</script>

<style scoped>
img {
  width: 150px;
  height: 150px;
  object-fit: cover;
  overflow: hidden;
}
video {
  /* width: 150px;
  height: 150px;
  object-fit: fill;
  overflow: hidden; */
  /* object-fit: fill; */
}

.break-world {
  word-break: break-all;
}

.card-item {
  border-width: 2px;
}

.action__icon {
  width: 20px;
  height: 20px;
  filter: invert(49%) sepia(7%) saturate(539%) hue-rotate(167deg)
    brightness(90%) contrast(88%);
}
</style>
