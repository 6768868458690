<template>
  <div class="pb-5">
    <base-page-title :titleText="activeProject.name" />
    <!-- <h2 class="mt-2 ml-3 mb-4">
      {{ activeProject.name }}
    </h2> -->
    <b-col class="mt-5 col-12 col-md-8 col-lg-6">
      <h4 class="mt-2">
        {{ $t("page.projectDetails.uploadVideos") }}
      </h4>
      <b-card>
        <template v-slot:overlay>
          <h4 class="text-red text-uppercase text-center">
            {{ $t("stimulus.noMoreVideosAllowedMsg") }}
          </h4>
        </template>
        <div
          v-if="!loadingVideo && !showVideoCaption"
          class="upload-section-wrapper rounded bg-gray-light"
          :class="{ 'custom-shadow': dragoverActive }"
        >
          <div
            class="upload-section rounded h-100"
            @drop.prevent="uploadLocalFile"
            @dragover.prevent="dragover"
            @dragenter="dragoverActive = true"
            @dragleave="dragoverActive = false"
          >
            <input
              accept="video/*"
              ref="inputUpload"
              type="file"
              name="file-input"
              @change="uploadLocalFile"
            />
            <b-row
              class="no-gutters h-100 justify-content-center"
              align-v="center"
            >
              <b-col class="col-12 text-center">
                <img
                  src="../assets/general-icons/upload-alt.svg"
                  class="upload__icon"
                />
                <p class="mb-0 font-weight-bold">{{ dropFileMsg }}</p>
                <p class="mb-1">{{ $t("or") }}</p>
                <b-button
                  variant="outline-secondary"
                  class="mx-auto"
                  size="sm"
                  @click="openUploadDialog"
                >
                  {{ $t("button.chooseFile") }}
                </b-button>
              </b-col>
            </b-row>
          </div>
        </div>
        <div v-else class="video-preview">
          <b-row
            v-if="loadingVideo"
            class="no-gutters h-100 w-100 bg-red"
            align-v="center"
          >
            <b-col class="col-12 text-center text-white">
              <b-icon
                icon="arrow-clockwise"
                animation="spin-pulse"
                font-scale="4"
                variant="white"
              ></b-icon>
              <div class="text-uppercase">
                {{ $t("loading") }}
              </div>
            </b-col>
          </b-row>
          <video
            class="w-100 h-100"
            preload="metadata"
            hidden
            ref="video"
            @canplay="onVideoLoad"
            :src="`${fileInfo.src}#t=1.5`"
          ></video>

          <canvas hidden ref="canvas"></canvas>
        </div>

        <b-form>
          <p class="mt-3 mb-1 title__3">
            {{ $t("project.videoName") }}
          </p>
          <b-form-input v-model="fileInfo.name"></b-form-input>
          <b-row class="no-gutters justify-content-end align-items-center">
            <!-- <b-button
                v-if="formType === 'EDIT'"
                class="mr-1 mb-1 button--dark-border"
                variant="outline-dark"
                @click="cancelEditAction"
                >
                {{ $t("button.cancel") }}
                </b-button> -->
            <b-button
              class="mb-1 mt-3"
              :disabled="!isFormValid"
              @click="sumbit"
            >
              {{ $t(submitBtnLabel) }}
            </b-button>
          </b-row>
        </b-form>
      </b-card>
    </b-col>

    <b-row class="px-3 mt-4">
      <b-col
        cols="3"
        class="mt-4"
        :key="asset._id"
        v-for="asset of projectAssets"
      >
        <client-video-item :asset="asset"></client-video-item>
      </b-col>
    </b-row>

    <h4 class="px-3 mt-4 ">Hide metrics</h4>
    <b-row class="px-3">
      <b-col class="mt-2" cols="auto">
        <b-form-checkbox
          size="lg"
          v-model="hideMetrics"
          name="check-button"
          switch
        >
          <span v-if="hideMetrics">ON</span>
          <span v-else>OFF</span>
        </b-form-checkbox>
      </b-col>
    </b-row>

    <h4 class="px-3 mt-4 ">
      {{ $t("page.projectDetails.uploadAnalysisFile") }}
    </h4>
    <b-row class="px-3">
      <b-col class=" mt-2" cols="auto">
        <div
          class="mb-2"
          v-if="!activeProject.analysis && !analysisFile"
          style="color: red; font-style: italic"
        >
          No file uploaded
        </div>
        <input
          accept="application/json"
          ref="importFile"
          type="file"
          name="file-input"
          style="display:none"
          @change="uploadJsonFile"
        />
        <b-button
          variant="outline-secondary"
          class="mx-auto"
          size="sm"
          @click="openUploadJsonDialog"
        >
          {{ $t("button.chooseFile") }}
        </b-button>
        {{ analysisFileName }}
      </b-col>
      <b-col class=" mt-2" cols="12">
        <div>
          <b-button
            variant="primary"
            class=""
            @click="createProjectAnalysis"
            :disabled="
              (!isAnalysisFileUploaded || projectAssets.length < 1) &&
                !unsavedChanges
            "
          >
            {{ $t("button.saveChanges") }}
          </b-button>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import uniqueId from "lodash/uniqueId";
import ClientVideoItem from "../components/Client/ClientVideoItem";
import { mapState } from "vuex";

export default {
  components: {
    ClientVideoItem
  },
  data() {
    return {
      loadingVideo: false,
      showVideoCaption: false,
      dragoverActive: false,
      fileInfo: {},
      thumbnailBase64: "",
      scale: 0.25,
      videosAdded: 0,
      analysisFile: null,
      hideMetrics: false
    };
  },
  created() {
    this.resetStimulusInfo();
  },
  mounted() {
    this.resetUploadForm();
    this.hideMetrics = this.activeProject.step === -1;
  },
  methods: {
    resetStimulusInfo() {
      this.fileInfo = {
        _id: null,
        name: "",
        category: "",
        type: "",
        contentTypeOther: "",
        status: "",
        requiredRespondents: 0,
        file: {},
        src: null
      };
    },
    resetUploadForm() {
      this.resetStimulusInfo();
      this.loadingVideo = false;
      this.showVideoCaption = false;
      if (this.$refs.video) {
        this.$refs.video.hidden = true;
      }
    },
    sumbit() {
      // if (this.isNewFormType)
      this.addVideo();
      // else this.updateVideo();
    },

    openUploadJsonDialog() {
      this.$refs.importFile.click();
    },

    uploadJsonFile(e) {
      const src = e.target;
      const uploadedFiles = src.files;
      if (!uploadedFiles) return;
      else if (uploadedFiles.length !== 1) {
        return;
      }

      if (uploadedFiles[0].type !== "application/json") {
        console.log("Only json files allowed");
        // return;
      } else {
        this.analysisFile = uploadedFiles[0];
      }
      // console.log("ee");
      // this.createProjectAnalysis(this.analysisFile);
    },

    async createProjectAnalysis() {
      const params = {
        clientId: this.$route.params.clientId,
        projectId: this.$route.params.projectId,
        analysisFile: this.analysisFile
      };
      this.$store.commit("loader/SET_LOADER", {});

      try {
        await this.$store.dispatch("clients/hideMetrics", this.hideMetrics);
        if (this.analysisFile) {
          await this.$store.dispatch("projects/createProjectAnalysis", params);
        }
        await this.$store.dispatch("clients/retrieveProject", {
          clientId: this.$route.params.clientId,
          projectId: this.$route.params.projectId
        });
        this.$store.commit("success/SET_SUCCESS_INFO", {
          titleText: "Success",
          messageText: "Changes saved succesfully",
          // confirmBtnCallback: () =>
          //   this.$router.push({
          //     name: "ClientProjects",
          //     params: {
          //       id: this.$route.params.clientId
          //     }
          //   }),
          confirmBtnLabel: "Close"
        });
      } catch (error) {
      } finally {
        this.analysisFile = null;
        this.$store.dispatch("loader/close");
      }
    },

    uploadLocalFile(e) {
      const src = e.dataTransfer || e.target;

      const uploadedFiles = src.files;
      if (!uploadedFiles) return;
      else if (uploadedFiles.length > 1) {
        this.$store.commit("error/SET_ERROR", {
          messageText: "stimulus.errors.manyUploadedFiles.text"
        });
        return;
      }

      this.fileInfo.file = uploadedFiles[0];

      if (this.fileInfo.src) {
        URL.revokeObjectURL(this.fileInfo.src);
      }

      this.fileInfo.src = URL.createObjectURL(this.fileInfo.file);

      // this.fileInfo.src = this.vuURL.createObjectURL(this.fileInfo.file);

      if (!this.isVideo(this.fileInfo.file.type)) {
        this.$store.commit("error/SET_ERROR", {
          messageText: "stimulus.errors.invalidFileFormat.text"
        });
        return;
      }

      this.dragoverActive = false;
      this.loadingVideo = true;

      // this.setDataURL();
    },
    openUploadDialog() {
      this.$refs.inputUpload.click();
    },
    dragover(event) {
      event.dataTransfer.dropEffect = "move";
    },
    addVideo() {
      this.$store.commit("loader/SET_LOADER", {
        messageText: "dialog.loader.uploadVideo"
      });
      const video = { ...this.fileInfo };
      const totalVideos = this.projectAssets.length;
      this.$store
        .dispatch("projects/createProjectAsset", {
          asset: video,
          projectId: this.$route.params.projectId
        })
        .then(() => {
          if (totalVideos === 0)
            return this.$store.dispatch("projects/setProjectThumbnail");
        })
        .catch(() => {})
        .finally(() => {
          this.resetUploadForm();
          this.$store.dispatch("loader/close");
        });
    },
    updateVideo() {
      const video = { ...this.fileInfo };
      this.$store
        .dispatch("stimulus/updateProjectAsset", video)
        .then(() => {
          this.$emit("updateVideo", { ...this.fileInfo });
          this.resetUploadForm();
        })
        .catch(() => {})
        .finally(() => this.$store.dispatch("loader/close"));
    },
    setDataURL() {
      this.fileInfo.src = URL.createObjectURL(this.fileInfo.file);
      this.$nextTick(() => {
        this.$refs.video.onload = function() {
          URL.revokeObjectURL(this.fileInfo.src);
        };
      });
    },
    onVideoLoad() {
      this.loadingVideo = false;
      this.showVideoCaption = true;
      this.$refs.video.hidden = false;
      this.fileInfo._id = uniqueId("file_");

      if (this.projectAssets.length === 0) {
        const canvas = this.$refs.canvas;
        const video = this.$refs.video;

        canvas.width = video.videoWidth; // * this.scale;
        canvas.height = video.videoHeight; // * this.scale;

        canvas
          .getContext("2d")
          .drawImage(video, 0, 0, video.videoWidth, video.videoHeight);

        this.thumbnailBase64 = canvas.toDataURL();
        // this.videosAdded++;
        this.$store.dispatch("projects/setProjectThumbnail", {
          clientId: this.$route.params.clientId,
          projectId: this.$route.params.projectId,
          thumbnail: this.base64ToFile(this.thumbnailBase64)
        });
      }
    },
    base64ToFile(dataURI) {
      var arr = dataURI.split(",");
      var mime = arr[0].match(/:(.*?);/)[1];
      var bstr = atob(arr[1]);
      var n = bstr.length;
      var u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], `projectThumbnail.png`, { type: mime });
    },
    cancelEditAction() {
      return this.$emit("cancelEditAction");
    },
    isVideo(type) {
      const containerFormat = type.split("/");
      return containerFormat[0] ? containerFormat[0] === "video" : false;
    },
    isContentTypeSelected(contentType) {
      return this.fileInfo.type ? this.fileInfo.type === contentType : false;
    },
    isDevelopmentStageSelected(status) {
      return this.fileInfo.status ? this.fileInfo.status === status : false;
    }
  },
  computed: {
    ...mapState("projects", ["projectAssets"]),

    unsavedChanges() {
      const step = this.hideMetrics ? -1 : 0;
      console.log(this.activeProject.step, step);
      return this.activeProject.step !== step;
    },

    activeProject() {
      return this.$store.state.clients.activeProject;
    },

    isAnalysisFileUploaded() {
      return this.analysisFile !== null;
    },

    analysisFileName() {
      if (!this.analysisFile) return "";
      else if (!this.analysisFile.name) return "Not available";
      else return this.analysisFile.name;
    },
    submitBtnLabel() {
      return "button.uploadVideo";
    },

    dropFileMsg() {
      const file = this.$t("video");
      return this.$t("stimulus.dropFile", { file });
    },

    isFormValid() {
      return this.fileInfo._id !== null && this.fileInfo.name.length > 0;
    }
  }
};
</script>

<style scoped>
.upload-section-wrapper,
.video-preview {
  height: 300px;
}
.upload-section-wrapper {
  height: 300px;
  border: 2px dashed var(--gray);
  padding: 2px;
}
.custom-shadow {
  box-shadow: inset 0 0 20px 1px var(--gray);
}
.upload-section input {
  display: none;
}
.form__overlay {
  z-index: 0;
}

.upload__icon {
  width: 100px;
  filter: invert(49%) sepia(7%) saturate(539%) hue-rotate(167deg)
    brightness(90%) contrast(88%);
}

.btn-outline-secondary {
  background-color: white;
  border-radius: 6px;
}
.btn-outline-secondary:hover {
  background-color: #6c757d;
}

.custom-control.custom-control-inline.custom-radio
  >>> .custom-control-label::before,
.custom-control.custom-control-inline.custom-radio
  >>> .custom-control-label::after {
  top: 0.1rem;
}

/* video {
  object-fit: fill;
  overflow: hidden;
} */
</style>
